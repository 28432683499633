#contact-form {

    .contact-form-title {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 38px;
        color: #2178B4;
        text-align: center;
    }

    .label-title {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 1rem;
    }

    .label-add-title {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        margin-bottom: 0;
        margin-top: 8px;
    }

    .add-attachment {
        font-family: 'Lato', sans-serif;
        margin-left: 2px;

    }

    .input-field-style {
        display: block;
        width: 100%;
        padding: .375rem 0rem;
        font-size: 1rem;
        // opacity: 0.2;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid #DDDDDD;
        -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
    }

    input:focus {
        outline: none;
    }

    textarea:focus {
        outline: none;
    }

    ::-webkit-input-placeholder {
        color: white;
        opacity: .2;
        /* Firefox */
    }

    :-ms-input-placeholder {
        color: white;
        opacity: .2;
        /* Firefox */
    }

    ::-ms-input-placeholder {
        color: white;
        opacity: .2;
        /* Firefox */
    }

    ::placeholder {
        color: white;
        opacity: .2;
        /* Firefox */
    }

    .send-btn-style {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        /* line-height: 12px;
        text-align: right; */
        background-color: transparent;
        color: #FFFFFF;
        text-transform: capitalize;
        border: 0;
        /* margin-bottom:1px; */
    }

    .send-btn-style:hover {
        color: #2178B4;
    }

    .send-icon-holder {
        background: #237BB7;
        -webkit-box-shadow: 0px 0px 50px rgba(244, 128, 57, 0.4);
        box-shadow: 0px 0px 50px rgba(244, 128, 57, 0.4);
        display: inline-block;
        padding: 1rem;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        font-size: 1rem;
        color: white;
    }

    .arrow-icon {
        margin-bottom: 6px
    }

    // .contact-form-title {
    //     font-family: 'Poppins';
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 36px;
    //     line-height: 38px;
    //     color: #2178B4;
    //     text-align: center;
    // }

    // .label-title {
    //     font-family: 'Poppins';
    //     font-style: normal;
    //     font-weight: 500;
    //     font-size: 13px;
    //     line-height: 15px;
    //     text-transform: uppercase;

    //     color: #FFFFFF;
    //     margin-bottom: 1rem;
    // }

    // .label-add-title {
    //     font-family: 'Poppins';
    //     font-style: normal;
    //     font-weight: 500;
    //     font-size: 16px;
    //     line-height: 19px;

    //     color: #FFFFFF;
    // }

    // .input-field-style {
    //     display: block;
    //     width: 100%;
    //     padding: .375rem 0rem;
    //     font-size: 1rem;
    //     font-weight: 400;
    //     line-height: 1.5;
    //     color: #212529;
    //     -webkit-appearance: none;
    //     appearance: none;
    //     background-color: transparent;
    //     border: 0;
    //     border-bottom: 1px solid #DDDDDD;
    //     transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    //     font-family: 'Poppins';
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 19px;
    //     color: #FFFFFF;


    // }

    // input:focus {
    //     outline: none;
    // }

    // textarea:focus {
    //     outline: none;
    // }

    // ::placeholder {
    //     color: rgb(255, 255, 255);
    //     opacity: .8;
    //     /* Firefox */
    // }

    // .send-btn-style {
    //     font-family: 'Poppins';
    //     font-style: normal;
    //     font-weight: 500;
    //     font-size: 16px;
    //     line-height: 19px;
    //     text-align: right;
    //     background-color: transparent;
    //     color: #FFFFFF;
    //     text-transform: capitalize;
    //     border: 0;
    // }

    // .send-btn-style:hover {
    //     color: #2178B4;
    // }

    // .arrow-icon {
    //     margin-bottom: 6px
    // }

    // .send-icon-holder {
    //     background: #237BB7;
    //     box-shadow: 0px 0px 50px rgba(244, 128, 57, 0.4);
    //     display: inline;
    //     padding: 1rem;
    //     border-radius: 50%;
    //     font-size: 1rem;
    //     color: white;
    // }

    @media only screen and (max-width: 767px) {
        .contact-form-title {
            font-size: 20px;
        }

        .add-attachment {
            font-size: .9rem;
        }

        .send-btn-style {
            margin-right: .5rem !important;
            font-size: .9rem;

        }

        .send-icon-holder {
            background: #237BB7;
            box-shadow: 0px 0px 50px rgba(244, 128, 57, 0.4);
            display: inline-block;
            padding: .5rem;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            font-size: 1rem;
            color: white;
        }

        .arrow-icon {
            margin-bottom: 0;
            font-size: .8rem;
        }
    }

}