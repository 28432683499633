#work-engagement {
  .solutions-banner {
  }
  .swiper-pagination-bullet {
    background: white;
  }
  @media (min-width: 576px) {
    .solutions-banner {
      margin-top: 4rem;
    }
  }
}
