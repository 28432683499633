#contributor-card {
    .contributor-img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
        border-radius: 50%;
    }

    .contributor-card-inner {
        background-color: transparent;
        margin-left: 3rem;
    }

    .contributor-name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        margin: 0;
    }

    .contributor-name:hover {
        color: #237BB3;
    }

    .contributor-total-number {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;

        color: #237BB3;
        margin: 0;
        mix-blend-mode: normal;
        opacity: 0.5;
    }
}