#teamA {
    .a-team-title-txt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        // line-height: 18px;
        letter-spacing: 0.2px;
        color: #237BB3;
        text-align: center;

    }

    .a-team-sub-txt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        /* or 50% */
        letter-spacing: 0.2px;
        color: #237BB3;

    }




}