#recent-project-card {
    .project-img-card-1 {
        background: #E6EBF2;
        border: 1px solid #1B5D8A;
    }

    .project-img-card-2 {
        background: #E6EBF2;
        border: 1px solid #1B5D8A;
    }

    .project-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 39px;
        letter-spacing: 0.1em;
        color: #000000;
        text-align: center;
        margin: 0;

        @media only screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 24px;
        }
    }
}