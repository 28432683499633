#team-card {
    .team-a-card {
        background: linear-gradient(0deg, rgba(234, 190, 190, 0.05), rgba(234, 190, 190, 0.05)), rgba(35, 123, 179, 0.49);
        border-radius: 10px 10px 60px 10px;
        text-align: center;

    }

    .team-a-card-inner {
        background: rgba(151, 151, 151, 0.13);
        mix-blend-mode: normal;
        border: 3px solid #FFFFFF;
        border-radius: 10px 10px 60px 10px;
        margin: 36px;
        padding: 24px;
    }

    .top-emp-img {

        width: 230px;
    }

    .team-a-card-name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #FFFFFF;
    }

    .team-a-card-designation {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300px;
        font-size: 16px;
        color: #FFFFFF;
    }

    .team-a-card-btn {
        background: #FFFFFF;
        border: 1px solid #237BB3;
        border-radius: 30px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #237BB3;
    }

    .team-a-card-social-icon {
        color: #FFFFFF !important;
        font-size: 1.2rem;

    }
}