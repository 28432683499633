#a-team {
  .founding-members {
    img {
      width: 250px;
      height: 300px;
      object-fit: cover;
      border-radius: 15px;
    }

    h2 {
      text-transform: uppercase;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 52px;
      letter-spacing: 0em;
    }

    p {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.06em;
      color: #ffffff;
      text-align: center;
    }

    .brain-behind {
      font-size: 1.5625rem;
      color: #fff;
      padding-left: 16px;
    }

    .members-title {
      font-size: 30px;
    }

    @media only screen and (max-width: 576px) {
      .brain-behind {
        font-size: 1.25rem;
      }

      h2 {
        font-size: 18px;
      }

      .members-title {
        font-size: 20px;
      }
    }
  }

  .members {
    .members-title {
      font-size: 30px;
    }

    .members-name-title {
      font-size: 19px;
    }

    .card {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 12px;
      backdrop-filter: blur(15px);


      img {
        width: 80%;
        height: 75%;
        // object-fit: cover;
      }

      .designation {
        font-size: 14px
      }
    }

    @media only screen and (max-width: 576px) {
      .members-title {
        font-size: 20px;
      }

      .members-name-title {
        font-size: 19px;
      }

      // .member-image {
      //   width: 85% !important;
      // }
    }
  }

  .btn {
    height: 60px;
    width: 240px;
    border-radius: 50px;
    margin-bottom: 77px;
  }

  .designation-box {
    background-color: white;
  }

  .box-content .name {
    margin-top: 24px;
    font-weight: bold;
  }

  .image-top {
    margin-bottom: 80px;
  }

  .member-image {
    max-height: 281px !important;
    margin-top: -50px;
  }

  .card:hover {
    .member-image {
      margin-top: -60px;
      transition: 0.7s;
    }
  }

  .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // Glassmorphism design start

  #social-icon {
    ul {
      position: relative;
      display: flex;
      z-index: 2;
      list-style: none;
      padding: 0;
    }

    ul li {
      position: relative;
      list-style: none;
      margin: 20px 10px;
    }

    ul li a {
      position: relative;
      width: 50px;
      height: 50px;
      display: inline-block;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: #fff;
      font-size: 2em;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(2px);
      transition: 0.5s;
      overflow: hidden;
    }

    ul li a:hover {
      transform: translateY(-15px);
    }

    ul li a:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      transform: skewX(45deg) translateX(150px);
      transition: 0.5s;
    }

    ul li a:hover:before {
      transform: skewX(45deg) translateX(-150px);
    }
  }

  .type::after {
    content: "";
    animation: cursor 1.1s infinite step-start;
  }

  @keyframes cursor {
    50% {
      opacity: 0;
    }
  }

  // Glassmorphism design End
  @media screen and (max-width: 768px) {
    .founding-members p {
      display: block;
      margin: 0 45px;
    }

    .member-image {
      max-height: 100% !important;

    }

  }

  .brain-behind {
    font-size: 1.5625rem;
  }

  @media only screen and (max-width: 576px) {
    .brain-behind {
      font-size: 1.25rem;
    }


  }

  @media screen and (max-width: 991.98px) {

    .it-team,
    .marketing-team,
    .design-team {
      .heading {
        h1 {
          font-size: 26px;
        }
      }
    }
  }
}