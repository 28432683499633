#products {
  min-height: 100vh;
  .products-banner {
    min-height: 80vh;
    display: flex;
    align-items: center;
  }
  .product-card {
    background: transparent !important;
    transition: 0.5s;
  }

  .product-card:hover {
    background: #ffffff !important;
  }

  .product-card:hover p {
    color: #000000 !important;
  }

  .product-features {
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0.005em;
    }
  }
  @media screen and (max-width: 768px) {
    .button-style {
      margin-bottom: 10px;
    }
  }
}
