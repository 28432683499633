#service-col {
  button.clamp-lines__button {
    display: block;
    margin: auto;
    padding: 10px 30px;
    border-radius: 10px;
    background-color: transparent;
    border: none;
  }
  p#clamped-content-custom {
    color: white;
  }
  .card-title {
    height: 3.75rem;
  }
  @media only screen and (max-width: 767.98px) {
    .card-title {
      height: auto;
    }
  }
}
