#top-contributors-container {
    .top-contributors-img {
        // border-radius: 50%;
        width: 90%;
    }

    .contributor-container {
        // border: 3px solid #237BB3;
        padding: 20px;
    }

    .contributor-items-container {
        max-height: 250px;
        overflow-y: auto;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #237BB3;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #237cb3b6;
    }



    .top-contributors-header-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #237BB3;
        text-align: center;



    }
}