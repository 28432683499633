#services2 {
  .parallax-effect-services {
    height: 60vh;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
      url(../../assets/images/BackgroundImage/bg_service.jpeg) no-repeat 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .service-title {
    font-size: 30px;
  }
  .nav-pills {
    position: relative;
    cursor: pointer;
  }
  .nav-pills .active {
    position: relative;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    span {
      color: white;
    }
  }
  .nav-pills .active:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    background: #ffffff0d;
    backdrop-filter: blur(15px);
    // background: rgba(255, 255, 255, 0.5);
    transform: skewX(45deg) translateX(180px);
    transition: 0.5s;
  }
  .nav-pills .active:hover:before {
    transform: skewX(45deg) translateX(-150px);
  }
  .nav-link {
    color: white;
  }
  .font-size {
    font-size: 18px;
  }
  .services-card {
    background: transparent;
    padding: 15px;
  }
  .services-card:hover {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(15px);
    transform: scale(1.05);
    transition: 0.7s;
  }
  .card-icon {
    height: 40px;
    width: 40px;
    display: block;
    margin: auto;
  }
  .services-tab-content {
    height: 600px;
    width: auto;
    overflow-y: scroll;
    padding: 0 30px;
  }
  @media only screen and (max-width: 768px) {
    .service-title {
      font-size: 20px;
    }
    .font-size {
      font-size: 16px;
    }
  }
}
