#letter-content{
    background-color: white;
    
    height: 11.7in;
    width: 8.7in;
    border-left: 18px solid #009cb3;
    border-bottom: 18px solid #009cb3;
    border-radius: 10px;
   
}

#letter-content-body{
    
    /* position: relative; */
}

.letter-body-text{
    text-align: start;
    padding-top: 110px;

}

.letter-footer-text{
    position: relative;
    top: 520px;

    
    
   
}

.pdf-download-btn{

}

.footer-icon{
    background-color: #009cb3;
    color: white;
    font-size: 1.5rem;
    padding: 5px;
    border-radius: 47%;
    
}

.meeting-header{
    color:black;
    font-size: 1rem;
     font-weight: 400px;
    font-family: 'Poppins', sans-serif;
}
.buttons-pdf{
    border: 1px solid white;
    border-radius: 5px;
    background-color:#1565D8;
    color:white;
    border:none;
    font-size: 14px;
     font-family: 'Poppins', sans-serif;

}
.meeting-information{
    color:black;
    font-family: 'Poppins', sans-serif;
    font-weight: 800px;
     font-size: 14px;
     margin:0;


}
.project-text{
     font-weight: 300px;
     font-size: 13px;

}
#content{
    /* padding: 2px; */
    width:587px;
    height:100%;
    padding: 4px;

}
.table-details{
    /* height: 550px; */
    background-color: white;
}
.table-header{
    color:white;
    background-color:rgb(47, 36, 58);
    align-items: center;
}
.call-logo{
    color:white;
    font-size:30px;
      background-color:#1565D8;
      border-radius: 50%;
      padding: 7px;
    
}
.mobile-text{
    color:black;
     font-family: 'Poppins', sans-serif;
     font-weight: 300px;
     font-size: 10px;
     

}
.footer-minutes{
    display: grid;
    

}
.full-page{
    background-color: white;
    /* background-image: url(); */
    /* background-color: #111215; */
    /* border-left:17px solid #1565D8;
    border-bottom:17px solid #1565D8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius:10px;
     border-top-left-radius:10px; */
     
}
.form-border{
     background-color: white;
    /* background-color: #111215; */
    border-left:17px solid #1565D8;
    border-bottom:17px solid #1565D8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius:10px;
     border-top-left-radius:10px;

}
.location-text{
    font-size:11px;
    font-weight: 300px;

}


.top-information{
    margin:0;
     font-family: 'Poppins', sans-serif;
     font-weight: 800px;
     font-size: 12px;
}

/* table design ---------------------------*/


.table-design{
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table-design td, .table-design th {
  border: 1px solid rgb(8, 8, 8);
  padding: 8px;
}

.table-design tr:first-child{background-color:#009CB4 ;
    color:white;
   


}

.table-design tr:hover {background-color: rgb(173, 173, 173);}

.table-design th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: black;
}

@media print {
    body * {
      visibility: hidden;
    }
    #letter-content, #letter-content * {
      visibility: visible;
    }
    #letter-content {
      position: absolute;
      left: 0;
      top: 0;
    }
  }