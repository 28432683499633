#blog-card {
    .blog-card {
        border: 0;
        border-radius: 0;
        background-color: transparent;
        text-align: left;
    }

    h5 {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .author-info {
        display: flex;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            border-radius: 50%;
        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            color: #237BB3;
            margin: 0;
        }
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 15px;
        color: #FFFFFF;
        text-align: left;
    }
}