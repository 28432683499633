.flagship-projects-container {
  margin-top: 70px;
}

#flagship-project {


  .img-fluid {
    width: 100%;
    display: block;
    margin: auto;
    height: auto;
  }
}

#flagship-projects {
  .card {
    position: relative;
    display: block;
    height: 100%;
    border-radius: 45px;
    overflow: hidden;
    text-decoration: none;
    background-color: transparent;
  }

  .card__image {

    width: 100%;
    height: auto;
  }

  .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 40px;
    background: #4040401c;
    border-radius: 12px;
    backdrop-filter: blur(15px);
    transform: translateY(100%);
    transition: 0.2s ease-in-out;
  }

  .card:hover .card__overlay {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  .card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2rem;
    border-radius: 40px 0 0 0;
    background: #4040401c;
    padding: 1.3em;
    transform: translateY(0%);
    transition: 0.2s ease-in-out;
  }

  .card__arc {
    width: 5em;
    height: 5em;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
  }

  .card__arc path {
    fill: #4040401c;
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
  }

  .card:hover .card__header {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  .card__thumb {
    flex-shrink: 0;
    width: 3.125em;
    height: 3.125em;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .card__title {
    font-size: 1em;
    margin: 0 0 0.3em;
    color: #fff;
  }

  .card__status {
    font-size: 0.8em;
  }

  .card__description {
    padding: 0 2em 2em;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  @media only screen and (max-width: 576px) {
    .card {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}