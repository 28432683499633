#latest-blogs {
    .see-all-btn {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        // line-height: 20px;
        letter-spacing: 1px;
        color: #237BB3;
        background-color: transparent;
        float: right;
    }
}