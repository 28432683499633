#blog-most-view {
    .blog-most-view {
        background: #FFFFFF;
        // border: 3px solid #237BB3;
        border-radius: 0;
        border: none;

    }

    .blog-most-view-cat {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 1px;
        color: #237BB3;
        mix-blend-mode: normal;
        text-align: start;
    }

    .blog-most-view-cat-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #237BB3;
    }

    .blog-most-view-cat-desc p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: #020202;
        text-align: start;
        opacity: 0.7;
        margin: 0;
    }
}