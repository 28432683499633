.solutions {
  .solution-title {
    font-size: 1.875rem;
  }

  .banner-image {
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 767px) {
    .banner-image {
      height: auto;
      width: 100%;
    }

    .solution-title {
      font-size: 1.25rem;
    }
  }
}