#blog-details {
    .blog-details-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        color: #FFFFFF;
        margin-bottom: 1.5rem;
    }

    .blog-author-img {
        width: 50px;
        height: 50px;
        margin-right: 12px;
        border-radius: 50%;
    }

    .author-name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #FFFFFF;
        text-align: left;
    }

    .blog-date {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #FFFFFF;
        text-align: left;
    }

    .blog-details-text p {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        color: #FFFFFF;
        text-align: left;
    }

    .details-blog-hr {
        border: 2px solid #237BB3;
        width: 50%;
        margin: auto;
        opacity: 1;
    }
}