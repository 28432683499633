#blog-category {

    .blog-catagory-items-container {
        max-height: 300px;
        overflow-y: auto;

    }

    .blog-category {
        // border: 3px solid #237BB3;
        border-radius: 0;
        padding: 20px;
        background-color: transparent;
    }

    h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #237BB3;
    }

    .blog-catagory-btn {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        background: rgba(153, 212, 255, 0.2);
        border: 1px solid #237BB3;
        width: 100%;
        text-align: start;
    }

    .blog-catagory-btn:hover {
        background-color: #FFFFFF;
        color: #237BB3;
        border: 1px solid #237BB3;
    }
}