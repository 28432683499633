#trending-card {
    .trending-card {
        background-color: transparent;
    }

    .trending-blog-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 25px;
        color: #fff;
        text-align: left;
    }
}