.careers {
  .job-card {
    background: transparent;
  }

  .apply-accordion {
    background: transparent !important;
    border: none;
  }

  .apply-card {
    background: transparent;
  }

  // accordion style start
  .accordion-button {
    background: rgba(255, 255, 255, 0.102);
    backdrop-filter: blur(15px);
  }
  .accordion-item,
  .accordion-body {
    // background: rgba(255, 255, 255, 0.102);
    background: #4040401c;
    backdrop-filter: blur(15px);
  }
  .accordion-body {
    p {
      background-color: transparent;
    }
  }
}

.job-circular {
  p {
    text-align: justify;
  }
}
