#job-circular {
  .open-position-title {
    font-size: 25px;
  }
  @media only screen and (max-width: 576px) {
    .open-position-title {
      font-size: 20px;
    }
  }
}
