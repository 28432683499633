.page-flip {
  display: flex;
  align-items: center;
  justify-content: center;
  .portfolio-title-border {
    border: 1px solid white;
    padding: 30px;
    margin-right: 20px;
    margin-top: 50%;
    border-radius: 0 0 50px 50px;
    background-color: transparent;
  }
  .page {
    border-left: 1px solid #000;
    cursor: pointer;
  }
}
