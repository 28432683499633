#recent-project-container {
    .recent-project-section-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 65px;
        text-align: center;
        color: #237BB7;
    }

    .recent-project-section-sub-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
    }
}