#services {
  figure {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    backdrop-filter: blur(15px);

    @media only screen and (max-width: 768px) {

      background: transparent;
    }

    img {
      width: 100%;

      @media only screen and (max-width: 768px) {
        max-width: 230px;
        background: #000;
      }
    }


  }

  figure:hover img {
    margin-top: -25px;
    transition: 0.6s;
  }

  .swiper-pagination-bullet {
    background: white;
  }

  .service-carousel {
    padding: 0 140px;
  }

  .swiper-slide {
    filter: blur(4px);
  }

  .swiper-slide-active {
    filter: blur(0px);
  }

  h3 {
    font-size: 1.562rem;
  }

  @media only screen and (max-width: 576px) {
    .service-carousel {
      padding: 0;
    }

    h3 {
      font-size: 1.25rem;

    }

    .swiper-button-next:after,
    .swiper-button-prev:after {

      font-size: 1.5rem !important;

    }

    .swiper-button-prev {
      height: 5px;


    }

    .swiper-button-next {
      height: 5px;
    }
  }
}