#rnd-contributor {
    .rnd-contributor-container {
        // border: 3px solid #237BB3;
    }

    .top-contributors-header-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #237BB3;
        text-align: center;

    }

    .rnd-contributor-details {
        // border: 3px solid #237BB3;
    }

    .contibutor-container-card {
        max-height: 300px;
        overflow-y: auto;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #237BB3;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #237cb3b6;
    }

    .table-title-txt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 26px;
        letter-spacing: 0.3px;
        color: #FFFFFF;
    }

    .table-inner-txt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 275;
        font-size: 12px;
        line-height: 26px;
        letter-spacing: 0.3px;
        color: #FFFFFF;
        padding-left: 20px;
    }

    .contributor-details-img {
        width: 90px;
        height: 90px;
        margin-right: 3rem;
    }

    .view-all-btn {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 0px;
        color: #237BB3;
        padding: 12px 20px;
        background: #FFFFFF;
        border: 1px solid #237BB3;
        border-radius: 30px;
    }
}