#solutions {

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: #000;
  }

  .carousel-indicators {
    bottom: -70px;
  }

  .banner-image {
    height: 90vh;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 576px) {
    .banner-image {
      height: auto;
      width: 100%;

    }
  }
}