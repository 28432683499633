.contact {
  min-height: 100vh;
  .contact-form {
    background: transparent;
  }
  .row {
    margin-top: 70px;
  }
  .contact-us {
    margin-top: 100px;
  }

  #social-icon {
    ul {
      position: relative;
      display: flex;
      z-index: 2;
      list-style: none;
      padding: 0 41%;
    }
    ul li {
      position: relative;
      list-style: none;
      margin: 20px 0px;
    }
    ul li a {
      position: relative;
      width: 70px;
      height: 70px;
      display: inline-block;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: #fff;
      font-size: 2em;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(2px);
      transition: 0.5s;
      overflow: hidden;
    }
    ul li a:hover {
      transform: translateY(-15px);
    }
    ul li a:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      transform: skewX(45deg) translateX(150px);
      transition: 0.5s;
    }
    ul li a:hover:before {
      transform: skewX(45deg) translateX(-150px);
    }
  }
  @media only screen and(max-width: 768px) {
    .row {
      margin-top: 0;
    }
  }
}
