#contact-container {
    .contact-container {
        background: #41b0fd;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        // border-radius: 40px;
        // padding: 28px 65px;
    }

    .lets-collaborate-text {
        h1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            color: #FFFFFF;
        }

        h2 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            color: #000000;
        }
    }

    .lets-collaborate-contact-box {
        // background: rgba(4, 4, 4, 0.74);
        background: #000;
        // border: 3px solid #FFFFFF;
        border-radius: 30px;
        padding: 0px 24px;
    }

    .lets-collaborate-contact-box {
        span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            color: #FFFFFF;
        }

        img {
            width: 60px;
        }
    }
}