.meeting-form-body{
background-color: #111215;
}

.control-btn{
    width:15%;
    border: 0;
    background-color: #1565D8;
    padding: 10px;
    margin: 10px;
    color: white;
}
.progressbar {
    /* width: 400px; */
    height: 10px;
    background-color: white;
    margin-bottom: 50px;
  }
  
.progressbar div {
    width: 33.3%;
    height: 100%;
    background-color: #1565D8;
  }