.header {
  background-color: #040404;
  font-size: 16px;

  .navbar-brand {
    margin: 0;
    padding: 0;
  }

  img {
    width: 10rem;
    // height: 65px;
    // height: 4.063rem;
    // margin-right: auto;
  }

  .link:hover {
    color: #237bb3 !important;
  }

  @media only screen and (max-width: 576px) {


    img {
      // height: 30%;
      max-width: 6.875rem;
    }
  }


}

@media only screen and (max-width: 768px) {
  #basic-navbar-nav {
    .toggle-btn-header {
      padding: .5rem;
    }

    .navbar-nav {
      padding: .5rem;
    }

  }
}