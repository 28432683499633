@import "~bootstrap/scss/bootstrap";
// @import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // color: #237bb3;
  font-family: "Montserrat", sans-serif;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #237BB3;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #237cb3b6;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #237bb3;
}

.title {
  font-size: 2.2rem;
  color: #237bb3;
}

.title-2 {
  font-size: 2.2rem;
  color: #237bb3;
}

@media only screen and(max-width: 575.98px) {
  .title {
    font-size: 1.562rem;
  }

  .title-2 {
    font-size: 1.2rem;
    color: #237bb3;
  }
}

p {
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.04em;
  font-family: "Montserrat", sans-serif;
  color: #237bb3;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 0;
}

img {
  width: 100%;
}

.app {
  background-color: black;
  min-height: 100vh;
}

.heading {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  p {
    font-size: 13px;
  }
}

.glass {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}