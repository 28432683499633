#home {
  #footer {
    width: 100%;
    color: #fff;
    font-size: 13px;
    background: #4040401c;
    border-left: 0.1px solid rgba(190, 190, 190, 0.377);
    border-top-left-radius: 125px;
    backdrop-filter: blur(15px);
    h4 {
      font-size: 1.562rem;
      margin-bottom: 40px;
      position: relative;
      display: inline-block !important;
    }
    .underline {
      width: 100%;
      height: 5px;
      border-radius: 3px;
      position: relative;
      overflow: hidden;
    }
    .underline span {
      width: 15px;
      height: 100%;
      background: #fff;
      border-radius: 3px;
      position: absolute;
      animation: moving 2s linear infinite;
    }
    @keyframes moving {
      0% {
        left: 0;
      }
      100% {
        left: 100%;
      }
    }
    p {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-align: justify;
      color: white;
    }
    ul {
      padding: 0;
      li {
        margin: 10px 0;
      }
      .link {
        text-decoration: none;
        color: white;
      }
    }
    .social-icons {
      .link:hover {
        margin-left: 10px;
        transition: 0.6s;
      }
    }
    @media only screen and (max-width: 576px) {
      h4 {
        font-size: 1.25rem;
      }
    }
  }
}
.video-player {
  height: 80vh !important;
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .video-player {
    height: auto !important;
    width: 100%;
  }
}
