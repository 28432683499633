#rnd-blog-container {
    .feature-blog-title {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 34px;
        color: #FFFFFF;
        backdrop-filter: blur(44px);

    }

    .rnd-hr {
        border: 2px solid #237BB3;
        width: 50%;
        opacity: 1;
    }
}