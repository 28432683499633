#rnd-contributor-blog-card {
    .rnd-contributor-blog-card {
        background-color: transparent;
    }

    h5 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: #fff;
        margin: 0;
    }

    .contributor-blog-img {
        max-height: 116px !important;
    }
}