#member-card {
    .member-card-main {

        background: rgba(35, 123, 179, 0.49);
        border-radius: 10px 10px 60px 10px;
        padding: 23px 17px;
        text-align: center;

    }

    .member-card-body {
        background: rgba(151, 151, 151, 0.13);
        mix-blend-mode: normal;
        border: 3px solid #FFFFFF;
        border-radius: 10px 10px 60px 10px;
        padding: 51px 47px;
    }

    .member-card-name {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
    }

    .member-card-designation {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
    }

    .team-a-card-social-icon {
        color: #FFFFFF !important;
        font-size: 1rem;

    }
}