#about {
  .parallax-effect-ourStory {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(../../assets/images/BackgroundImage/ourStory.png) repeat fixed 100%;
  }
  .parallax-effect-mission {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(../../assets/images/BackgroundImage/mission.png) repeat fixed 100%;
  }
  .parallax-effect-vision {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(../../assets/images/BackgroundImage/vision.png) repeat fixed 100%;
  }
  .vision-card {
    img {
      height: 50px;
      width: 50px;
    }
  }
  .our-story-responsive {
    margin: 48px 0;
    padding: 48px 0;
  }
  .work-engagement {
    font-size: 48px;
  }
  .sub-title {
    font-size: 30px;
  }
  .vision-title {
    font-size: 35px;
  }
  .title-description {
    font-size: 15px;
  }
  @media (max-width: 768px) {
    .title-border {
      margin-bottom: 40px;
    }
    .our-story-responsive {
      margin: 0;
      padding: 0;
    }
    .work-engagement {
      font-size: 25px;
    }
    .sub-title {
      font-size: 20px;
    }
    .title-description {
      font-size: 13px;
    }
    .vision-title {
      font-size: 20px;
    }
  }
}
.title-border {
  border: 1px solid white;
  padding: 30px;
  border-radius: 0 0 50px 50px;
  background-image: linear-gradient(0deg, #271139, #1b0e39);
}
.title-border-vision {
  border: 1px solid white;
  padding: 30px;
  display: inline-block;
  border-radius: 0 0 50px 50px;
  background-image: linear-gradient(0deg, #271139, #1b0e39);
}
.vision-image {
  height: 100px;
  width: 100px;
  display: block;
  margin: 0 auto;
}
.about-page {
  .about,
  .mission,
  .vision {
    p {
      color: #182f43;
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 768px) {
    .about,
    .mission,
    .vision {
      p {
        color: #182f43;
        font-size: 13px;
      }
    }
  }
}
