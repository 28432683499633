#project-rules {

    .project-roles-inner-container {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #237BB3;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #237cb3b6;
    }

    .table-title-txt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        color: #237BB3;
    }

    .table-inner-txt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 275;
        font-size: 16px;
        line-height: 32px;
        color: #FFFFFF;
    }

    .about-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        color: #237BB3;
        text-align: left;
    }

    .about-details {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 275;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        text-align: left;
    }
}