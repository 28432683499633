#rnd-blog-card {
    .rnd-blog-card {
        border: 0;
        border-radius: 0;
        background-color: transparent;
        text-align: left;
        margin: 1rem;
        width: 100%;

        img {
            width: 300px;
        }
    }

    h5 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #FFFFFF;
    }



    .author-info {
        display: flex;
        align-items: center;

        img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 0px;
            color: #237BB3;
            margin: 0;
        }
    }


}