#profile-desc {
    .description-container {
        // border: 3px solid #237BB3;
    }

    .table-title-txt {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
    }

    .table-inner-txt {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 275;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
    }



    .profile-card {
        text-align: center;
        background-color: transparent;
        // border: 3px solid #237BB3;
        padding: 1rem;
        border-radius: 0;

    }

    .profile-img {
        width: 61%;
        // max-height: 100%;
        // border-radius: 50%;
        margin: auto;
    }

    .name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        color: #FFFFFF;
    }

    .social-icon {
        color: #237BB3 !important;
        font-size: 1rem;

    }

    .profile-hr {
        border: 2px solid #237BB3;
        width: 25%;
        opacity: 1;
        margin: auto;

    }
}